import React from 'react'
import { useTranslate } from '@arfabrands/gatsby-theme-assembly'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import ErrorNotFoundPage from '~/components/Error/NotFound/Page'

const constants = {
  backgroundColor: '#265C5E',
  navTextColor: '#91D3CA'
}

const NotFound = () => {
  const translate = useTranslate()

  return (
    <Layout
      backgroundColor={constants.backgroundColor}
      navTextColor={constants.navTextColor}
    >
      <Metadata title={translate('error.not_found.page_title')} />
      <ErrorNotFoundPage />
    </Layout>
  )
}

export default NotFound

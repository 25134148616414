/** @jsx jsx */
import { Link } from 'gatsby'
import { Box, Button, Container, Text, Flex, Heading, jsx } from 'theme-ui'
import { useTranslate } from '@arfabrands/gatsby-theme-assembly'

const ErrorNotFoundPage = () => {
  const translate = useTranslate()

  return (
    <Box>
      <Container>
        <Flex
          sx={{
            alignItems: 'center',
            padding: ['3rem 1.5rem', '8rem 0'],
            flexDirection: 'column',
            minHeight: '60vh',
            textAlign: 'center',
            maxWidth: '850px',
            margin: 'auto'
          }}
        >
          <Heading as="h1" variant="h1"
            sx={{
              color: '#FFF',
              fontFamily: 'GT Walsheim Regular,Helvetica,Arial,Sans-Serif',
              marginBottom: '1rem',
            }}
          >
            {translate('error.not_found.title')}
          </Heading>
          <Text sx={{
            color: '#FFF',
            fontFamily: 'GT Walsheim Regular,Helvetica,Arial,Sans-Serif',
            marginBottom: '2rem',
          }}>
            {translate('error.not_found.text')}{' '}
            <Link to="/" sx={{ textDecoration: 'underline' }}>
              {translate('error.not_found.text_homepage_link')}
            </Link>
            .
          </Text>
          <Button as={Link} to="/shop/"
            sx={{
              color: 'rgb(81, 39, 115)',
              backgroundColor: 'rgb(197, 179, 215)',
              '&:hover': {
                color: 'primary',
                backgroundColor: 'secondary'
              },
              borderRadius: '0.25rem',
              fontFamily: 'DIN Alternate Bold,Helvetica,Arial,Sans-Serif',
              marginTop: '1rem',
              padding: '12px 16px',
              transition: 'filter 0.15s ease-in-out 0s',
            }}
          >
            {translate('error.not_found.cta')}
          </Button>
        </Flex>
      </Container>
    </Box>
  )
}

export default ErrorNotFoundPage
